import React from 'react'
import { Route, Switch, withRouter } from 'react-router-dom'
import LazyLoading from 'common/components/LazyLoading'
import styles from '../style/index.css'

const MapRoute = LazyLoading(() => import('views/example'))

module.exports = (
  <div className={styles.container}>
    <div className={styles.content}>
      <Switch>
        <Route exact path="/" component={MapRoute} />
        <Route path="*" component={MapRoute} />
      </Switch>
    </div>
  </div>
)
